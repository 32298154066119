.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input, .contact-form textarea {
  border-radius: 0.5rem;
  border-width: 1px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem;
  padding: 0.5rem;
}

.contact-form .invalid-input {
  border-color: red;
}

.container button.submit-button {
  background-color: #114444;
  border-color: #2E0014;
  border-radius: 0.5rem;
  border-width: 1px;
  color: #FEFCFB;
  font-family: 'Raleway', sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem;
  margin-left: auto;
  padding: 0.5rem;
  transition: all .5s;
  min-width: 250px;
}

.container button.submit-button:hover {
  background-color: #7FB285;
  border-color: #907AD6;
  cursor: pointer;
}

.container button.submit-button:disabled {
  background-color: #907AD6;
  border-color: black;
  cursor: default;
}