.header-text {
  font-weight: 200;
  text-align: center;
}

h1.header-text {
  text-transform: lowercase;
}

.header-container {
  border-radius: 4px;
  max-height: 200px;
  overflow-y: hidden;
  position: relative;
}

.header-image {
  width: 80%;
}

.transition {
  background: linear-gradient(90deg, rgba(17, 68, 68,0), rgb(17, 68, 68) 40%);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 80%;
}
