.contact {
  display: flex;
  margin: 2rem auto;
  justify-content: center;
}

.contact a {
  color: #114444;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  width: 100px;
}

.contact a span {
  font-weight: 800;
  transition: all .5s;
}

.contact a .icon {
  margin: 4px 8px;
  transition: all .5s;
}

.contact a:hover .icon {
  margin: 0 8px 8px;
}

.contact a:hover {
  color: #7FB285;
}