.background {
  background-color: rgb(254, 252, 251);
  margin: auto;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
