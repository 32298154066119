.images {
  margin: auto;
  text-align: center;
}

.images a {
  color: #114444;
  text-decoration: none;
  transition: all .5s;
}

.images a:hover {
  color: #7FB285;
}

.images img {
  max-width: 100%;
  max-height: 100%;
}

.image-centering-tool {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}