@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Raleway:wght@200&display=swap');

body {
  color: #114444;
  margin: 0;
  padding-bottom: 2rem;
  font-family: 'Merriweather';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3 {
  font-family: 'Raleway', sans-serif;
}
